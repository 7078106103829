.mat-raised-button , .mat-stroked-button{
  padding: 0 30px !important;
  border-radius: 18px !important;
}
.mat-stroked-button{
  border:1px solid $primary-blue !important;
}
button:focus{
  outline: none;
}

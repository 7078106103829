/* You can add global styles to this file, and also import other style files */

@import "scss/primary-colors";
@import "scss/secondary-colors";
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:400italic,700italic,400,600,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import "scss/material-overrides/overrides.scss";

$open-sans: 'Open Sans', sans-serif;

body {
  //overflow: hidden;

  >div:not(.cdk-overlay-container) {
    height: auto !important;
    min-height: 50vh;
    //overflow: hidden;
    font-family: $open-sans;

    a:hover {
      cursor: pointer !important;
    }

    input[type="text"] {
      // TODO: 04262019-need to remove if mat input is used or use commented code
      //border: 1px solid $primary-medium-gray-4;
      //border-width: 0 0 1px 0;
      background-color: $primary-white !important;
    }

    .mat-simple-snackbar-action {
      span {
        color: $primary-white;
      }
    }

    .modal-header {
      border-bottom: 0;
      div {
        display: table-cell;
        &:first-child {
          padding-right: 8px;
        }
      }
      span {
        word-break: break-all;
      }
    }

    .warning {
      color: $primary-light-red;
    }

    .padding-rt-12{
      padding-right:12px;
    }

    .gray-label{
      color: $primary-medium-gray-3;
    }

    .form-control[disabled] {
      background-color: $primary-medium-gray-4 !important;
      color: $primary-medium-gray-1;
      cursor: not-allowed !important;
    }

    .tab-sets {
      .tab-content {
        padding-top: 0;
      }
    }

    .action-btns {
      display: flex;
      justify-content: center;

      button {
        width: 110px;
        margin: 4px;
        border-radius: 22px;
        padding: 2px 28px;
      }
    }

    .advance-search {
      min-height: 430px;
      box-shadow: 1px 5px 15px 5px rgba(0, 0, 0, 0.2);

      /* Large screens ----------- */
      @media only screen  and (min-width : 1824px) {
        transform: translate(232px, 90px) !important;
        min-width: 76%;

        .arrow {
          top: -8px;
          left: 59% !important;
          transform: rotate(180deg) ;
        }
      }

      /* Desktops and laptops ----------- */
      @media only screen  and (min-width : 1224px) {
        transform: translate(140px, 90px) !important;
        min-width: 82%;

        .arrow {
          top: -8px ;
          left: 65% !important;
          transform: rotate(180deg) ;
        }
      }

      /* iPads (portrait and landscape) ----------- */
      @media only screen and (max-device-width : 1024px) {
        transform: translate(120%, 70px) !important;
        min-width: 320px;

        .arrow {
          top: -8px ;
          left: 50% !important;
          transform: rotate(180deg) ;
        }

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
          transform: translate(15%, 70px) !important;

          .arrow {
            top: -8px ;
            left: 86% !important;
            transform: rotate(180deg) ;
          }
        }
      }

      .mat-select-panel {
        margin-left: 20px;
      }

      .multiple-field-names {
        .mat-form-field-label-wrapper {
          display: none;
        }
      }
    }

    .select-all > .mat-pseudo-checkbox {
      display: none !important;
    }

    .title {
      .header-search {
        ngb-typeahead-window {
          max-height: 232px;
          overflow: auto;
          width: 100%;
          transform: translate(0px, 45px) !important;
        }
      }

      @media only screen and (min-device-width : 480px) {
        @media only screen and (min-device-width : 1024px) {
          .header-search {
            ngb-typeahead-window {
              transform: translate(0, 37px) !important
            }
          }
        }
      }
    }
  }

  .cdk-overlay-container {
    font-family: $open-sans !important;

    .cdk-global-overlay-wrapper {
      align-items: center !important;
    }

    .order-tracking {
      height: 92%;
      min-width: 95vw !important;

      .mat-dialog-container {
        padding-left: 2px;
        padding-right: 2px;
      }

      @media only screen and (min-device-width : 1024px) {
        height: 80%;
        width: 80%;
        min-width: 80vw !important;
      }
    }

    .order-notification {
      height: 57%;
      min-width: 95vw !important;

      .mat-dialog-container {
        background-color: $primary-light-grayish-blue;
      }

      @media only screen and (min-device-width : 1024px) {
        height: 50%;
        min-width: 40vw !important;
      }
    }
  }
}

@mixin text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}

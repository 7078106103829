/*
 * Primary Color Palette
 * =============================================================================
 * The great majority of visual designs will draw upon these colors exclusively.
 *
 *
 *
 * USAGE
 * ------------------------
 * Backgrounds
 * Grid background
 * button background
 * Hover for icons and text
 * Buttons on dark background
 */
$primary-white: #fff;

/*
 * USAGE
 * ------------------------
 * Status indicator
 */
$primary-light-red: #dc0a0a;

/*
 * USAGE
 * ------------------------
 * Status indicator
 */
$primary-orange-gradient-light: #ff9c01;

/*
 * USAGE
 * ------------------------
 *
 *
 */
$primary-moderate-blue: #52B6E4;
/*
 * USAGE
 * ------------------------
 *
 *
 */
$primary-blue: #007ac9;
/*
 * USAGE
 * ------------------------
 * Icon backgrounds
 * Theme color
 */
$primary-strong-blue: #327AC3;

/*
 * USAGE
 * ------------------------
 * Profile backgrounds
 *
 */
$primary-dark-green: #5b8f22;

/*
 * USAGE
 * ------------------------
 * status indicator
 *
 */
$primary-green: #387c2b;

/* USAGE
* ------------------------
* Titles
* Headers
* Body text
* Button text
* Grid text
* Text inside gray buttons
*/
$primary-dark-gray-1: #212121;

/*
 * USAGE
 * ------------------------
 *
 *
 */
$primary-dark-gray-2: #232427;

/*
 * USAGE
 * ------------------------
 * Primary buttons background (inactive state)
 * Data visualization
 */
$primary-medium-gray-1: #606169;
/*
 * USAGE
 * ------------------------
 * disabled
 */
$primary-medium-gray-3: #b2b2b8;
/*
 * USAGE
 * ------------------------
 * Inactive text and icons on dark backgrounds, such as Global Sub nav and Mega-menu
 */
$primary-medium-gray-4: #bababe;

/*
 * USAGE
 * ------------------------
 * checkbox hover
 */
$primary-light-gray-1: #ccc;

/*
 * USAGE
 * ------------------------
 * Borders around dropdowns, modals
 * Body background
 */
$primary-light-gray-2: #d5d4d9;

/*
 * USAGE
 * ------------------------
 * Form-Control border color
 */
$primary-light-gray-3: #ced4da;

/*
 * USAGE
 * ------------------------
 * Form-Control text color
 */
$primary-light-text: #495057;

/*
 * USAGE
 * ------------------------
 * page backgrounds
 */
$primary-light-grayish-blue: #efeff0;

/*
 * USAGE
 * ------------------------
 * disable text
 */
$primary-text-muted: #6c757d;
/*
* USAGE
* ------------------------
* disable text on blue BG
*/
$primary-text-dark-title: #C3E1FF;



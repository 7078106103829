
@use '@angular/material' as mat;
@import "scss/primary-colors";
$mat-myapp-primary:(
  50 : #e0eff9,
  100 : #b3d7ef,
  200 : #80bde4,
  300 : #4da2d9,
  400 : #268ed1,
  500 : #007ac9,
  600 : #0072c3,
  700 : #0067bc,
  800 : #005db5,
  900 : #004aa9,
  A100 : #d3e3ff,
  A200 : #a0c3ff,
  A400 : #6da2ff,
  A700 : #5392ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/

//@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
////@include mat.legacy-core();
@include mat.core();
$custom-theme-primary: mat.define-palette($mat-myapp-primary);
$custom-theme-accent:mat.define-palette($mat-myapp-primary);
$custom-theme-warn: mat.define-palette(mat.$red-palette);
$custom-theme: mat.define-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
//@include mat.all-legacy-component-themes($custom-theme);
@include mat.all-component-themes($custom-theme);
